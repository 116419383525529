<template>
    <div class="warp-head sticky" style="z-index:999">
        <div class="navi"></div>
        <div class="navigation">


            <router-link to="/Home"><a href="#" class="logo">
                    <!-- <img src="../assets/img/logo1.png" alt="" class="logo1">
                    
                    <img src="../assets/img/logo2.png" alt="" class="logo2"> -->
                    <!-- <div class="logo1"><img src="../assets/images/logo1.png" alt=""></div>
                    <div class="logo2"><img src="../assets/images/logo1.png" alt=""></div> -->
                    <!-- <div class="logo1">富升</div> -->
                    <div class="logo2">ACRDOCK</div>
                </a></router-link>
            <div class="email">
                <p>Email：yannickhe@acrdock.com</p>
                <p>Tel：18925328565，+86 189 2532 8685</p>
            </div>
            <ul class="menu_list">
                <div class="list-btn2" style="position: absolute;right: 0;">
                    X
                </div>
                <li class="menu_item">
                    <router-link to="/Home">首页/Home</router-link>
                </li>
                <li class="menu_item">
                    <router-link to="/Culture">关于/About</router-link>
                </li>
                <li class="menu_item">
                    <router-link to="/Center">产品/Product</router-link>
                    <ul class="sub_list">
                        <li>
                            <router-link to="/P_1"> > 游艇码头</router-link>
                        </li>
                        <li>
                            <router-link to="/P_2"> > 塑料浮筒</router-link>
                        </li>
                        <li>
                            <router-link to="/P_3"> > 船艇泊位</router-link>
                        </li>
                        <li>
                            <router-link to="/P_4"> > 光伏浮筒</router-link>
                        </li>
                        <li>
                            <router-link to="/P_5"> > 防浪堤</router-link>
                        </li>
                        <li>
                            <router-link to="/P_6"> > 海洋渔排</router-link>
                        </li>
                    </ul>
                </li>
                <li class="menu_item">
                    <router-link to="/Case">案例/Project</router-link>
                    <ul class="sub_list">
                        <li>
                            <router-link to="/Marina"> > 游艇码头</router-link>
                        </li>
                        <li>
                            <router-link to="/Plasticfloat"> > 塑料浮筒</router-link>
                        </li>
                        <li>
                            <router-link to="/Boat"> > 船艇泊位</router-link>
                        </li>
                        <li>
                            <router-link to="/Photovoltaic"> > 光伏浮筒</router-link>
                        </li>
                        <li>
                            <router-link to="/Breakwater"> > 防浪堤</router-link>
                        </li>
                        <li>
                            <router-link to="/Ocean"> > 海洋渔排</router-link>
                        </li>
                    </ul>
                </li>
                <li class="menu_item">
                    <router-link to="/News">新闻/News</router-link>
                </li>
                <li class="menu_item">
                    <router-link to="/Contact">联系/Contact</router-link>
                </li>
            </ul>
            <div class="list-btn">
                =
            </div>
        </div>

        <div class="off_area"></div>
    </div>
</template>

<script>
    window.onload = function () {
        var navi = document.querySelector(".navi")
        var menulist = document.querySelector(".menu_list")
        // var m = document.querySelectorAll(".menu_item")
        var btn = document.querySelector(".list-btn")
        var btn2 = document.querySelector(".list-btn2")
        var warphead = document.querySelector(".warp-head");
        var offarea = document.querySelector(".off_area")
        var logo1 = document.querySelector('.logo1')
        var logo2 = document.querySelector('.logo2')
        // warphead.style.backgroundColor = 'transparent'
        warphead.classList.remove("sticky")

        function topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }

        window.topFunction = topFunction

        // 侧边栏
        function Menu() {
            menulist.style.right = '-250px'
            offarea.style.display = 'none'
        }
        window.Menu = Menu

        offarea.addEventListener("click", function () {

            Menu()


        })

        //弹
        btn.addEventListener("click", function () {

            menulist.style.right = 0
            offarea.style.display = 'block'

        })
        //缩
        btn2.addEventListener("click",
            function () {
                Menu()

                // menulist.style.right = '-250px'

            }
        )
        // console.log(document.body.clientWidth)

        menulist.onmouseover = function () {
            // navi.style.height = '550px'
            // navi.style.height = '72px'
            if (document.body.clientWidth <= 768) {

                navi.style.height = 0
            }
        }

        window.addEventListener("scroll", function () {
            if (window.scrollY === 0) {
                warphead.classList.remove("sticky")
                logo1.style.display = 'block'
                logo2.style.display = 'none'
            } else {
                warphead.classList.add("sticky")
                logo1.style.display = 'none'
                logo2.style.display = 'block'
            }
        })
        window.addEventListener("resize", function () {
            if (document.body.clientWidth > 768) {

                menulist.style.right = 0
            } else {
                menulist.style.right = '-250px'
            }
        })
    }

    export default {

        watch: { //动态监听路由变化 -以便动态更改导航背景色事件效果等
            '$route'() {
                // 对路由变化作出响应...
                // console.log('to.path----', to.path) 
                //跳转后路由
                // console.log('from----', from) 
                //跳转前路由
                window.topFunction()
                if (window.innerWidth <= 768) {
                    window.Menu()
                }
                // console.log(1)
            }
        },
    }
</script>

<style src="../assets/css/header.css" scoped>

</style>