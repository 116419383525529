<template>
    <div class="Product-Center">
        <div class="Center-banner">
            <div class="Center-title">
                <h2>产品中心/
                    <p>Introduction</p></h2>
                <p>本公司已有十二年专业工作经验对于水上浮码头工程，水上钢、铝结构专业加工及施工水利土建工程保护所需水上隔离带等等.....
                    <br>Acrdock has more than 12 years of experience and is constantly expanding . Our team offers expert consulting, design, manufacturing and construction services.Our technical and production expertise in the field of floating docks enables us to minimize the cost and duration of the manufacturing process while maintaining the quality and safety of our products.
                </p>
                 <router-link to="/Case"><a href="#" class="btn">了解更多/More</a></router-link>
            </div>
            <!-- <img src="../assets/images/des-4.jpg" alt=""> -->
        </div>
        <div class="Center-wrap">
        <section class="services" id="services">            
            <div class="heading">
                <span>产品案例</span>
                <h1>专业团队为您服务</h1>
            </div>

            <div class="box-container">
                
                <div class="box" data-aos="zoom-in-up" data-aos-delay="150" v-for="item in items" :key="item.id">
                    <router-link :to="'/' + item.router">
                    <div class="image">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="content">
                        <i class="fas fa-globe"></i>
                        <h3>{{item.nameC}}</h3>
                        <p>{{item.nameE}}</p>
                    </div>
                    </router-link>
                </div>
                
            </div>

        </section>

        <section class="about " id="about">
            <div class="container">
                <div class="aboutus-wrapper d-flex">
                    <div class="aboutus-img"> 
                        <img src="../assets/images/des-1.jpg" alt="about image" class="img-fluid">
                    </div>
                    <div class="aboutus-content">
                        <h2>关于产品</h2>
                        <p>     浮筒： 组合式水上浮动平台组装简易、快速,造型多样化,可依需要而变化,组合成任意形状、任意大小的浮动平台<br>
                            <br>游艇码头：采用6061海事铝合金结构 / 热镀锌钢结构，具有让游艇停泊，水电供应，清洗，维修和游人上下船等功能.<br>
                            <br>船艇泊位: 安装拆卸方便、实用美观、自由组合，可以停放不同型号的的船只
                        </p><br>
                        <p>本公司已有十二年专业工作经验，对于水上浮码头工程，水上钢、铝结构专业加工及施工，水利土建工程保护所需水上隔离带等等.....</p>
<p>
                        <br>Modular pontoon: Our high quality modular floating system is a truly revolutionary product; it’s environmentally friendly, and requires no maintenance. Easy to use and reliable, it provides unlimited configuration and application options.<br>
                        <br>Aluminum /steel pontoon: The main structure of the frame construction consists of anticorodal marine grade aluminium 6061-T6 / Hot dip galvanized steel . It has the functions of berthing yachts, water and electricity supply, cleaning, maintenance and tourists getting on and off the ship<br>
                        <br>Boat berth: When stored in the water, jet ski hulls can suffer from delamination and intake fouling. With our floating jet ski docks and boat dock, you'll save your craft from undue wear and tear and improve ease of use. <br>

                        <br>Acrdock has more than 12 years of experience and is constantly expanding. We offer aluminium and steel pontoons, breakwaters, hdpe modular pontoons and other marina equipments.
</p>
                       <router-link to="/Case"> <a href="#" class="btn">了解更多/More</a> </router-link>
                    </div>
                </div>
            </div>
        </section>

        </div>

        <!-- banner section star-ts  -->

        <!-- <div class="banner">

            <div class="content" data-aos="zoom-in-up" data-aos-delay="300">
                <span>富升实业</span>
                <h3>专业浮动码头制造商</h3>
                <p>富升实业有限公司是一家专业从事于游艇码头，浮动码头，浮桥，浮动防波提，光伏浮筒及配套设施的企业，也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。本公司拥有一支从业经验超过10年的专业团队，积累了丰富的水上工程经验和技术。
                </p>
                <router-link to="/Contact"><a href="#book-form" class="btn">联系我们</a></router-link>
            </div>

        </div> -->

        <!-- banner section ends -->
    </div>
</template>

<script>
export default {
    data(){
       return {items:[
                    {
                        id:'01',
                        img:require('../assets/Case/Marinas/1/2.jpg'),
                        nameC:'游艇码头',
                        nameE:'Aluminum/Steel Pontoon',
                        router: "P_1"
                    },
                    {
                        id:'02',
                        img:require('../assets/images/blog-2.jpg'),
                        nameC:'塑料浮筒',
                        nameE:'Hdpe Modular Pontoon',
                        router: "P_2"
                    },
                    {
                        id:'03',
                        img:require('../assets/img/product3.jpg'),
                        nameC:'船艇泊位',
                        nameE:'Boat Berth',
                        router: "P_3"
                    },
                    {
                        id:'04',
                        img:require('../assets/Case/Float/1/3.jpg'),
                        nameC:'光伏浮筒',
                        nameE:' Floating Solar Plants',
                        router: "P_4"
                    },
                    {
                        id:'05',
                        img:require('../assets/img/barrier.jpg'),
                        nameC:'防浪堤',
                        nameE:'Floating Breakwater',
                        router: "P_5"
                    },
                    {
                        id:'06',
                        img:require('../assets/Case/Ocean/1/1.jpg'),
                        nameC:'海洋渔排',
                        nameE:'Deep Sea Fishing Cages',
                        router: "P_6"
                    }
                ],
       }
    }
}
</script>

<style src='../assets/css/Center.css'>
</style>