<template>
    <div class="Case">

        <div class="News-wrap">
            <h3>专业游艇码头 制造商<br>Professional Floating Dock Manufacturer</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">案例中心</el-breadcrumb-item>
                        <el-breadcrumb-item>塑料浮筒</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="Case_wrap">
            <div class="text-title">
                <h2>塑料浮筒</h2>
            </div>
            <div class="Case_item" v-for="item in arr.slice((currentPage-1) * pageSize, currentPage*pageSize)" :key="item.id">
                <router-link :to="'Page?type=2&id='+item.id">
                    <div class="Case_item_img">
                        <img :src="'https://www.acrdock.com/uploads/file/'+item.img.split(';')[0]" alt="">
                    </div>
                    <div class="Case_item_text">
                        <h3>项目名称：{{item.name}}</h3>
                        <h5>地点：{{item.location}}</h5>
                        <p>时间：{{item.date}}</p>
                    </div>
                </router-link>
            </div>
            <el-pagination background 
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[6,12,18,24]" :page-size="6" layout="total,sizes,prev, pager, next" :total="arr.length">
            </el-pagination>
        </div>

        <!-- <el-pagination background layout="prev, pager, next" :total="30">
        </el-pagination> -->
    </div>
</template>

<script>
    import axios from "axios"
    export default {
        data() {
            return {
                arr:[],
                currentPage:1,
                pageSize:6
            }
        },
        created() {
            axios({
                url: "https://www.acrdock.com/api/ytmt/getList?type=2",
                method: "get",
                timeout: 60000
            }).then((res) => {
                res.data.data.list.sort((a, b) => new Date(b.date).getTime() - new 
                Date(a.date).getTime()) //这是升序，倒序的话翻过来
                this.arr = res.data.data.list;
            });
        },
        methods:{
       //? 长度改变----改变每页显示的条数的时候  自动触发
       handleSizeChange(val){
           console.log("长度改变:"+val)
           this.pageSize = val
       },
       // 当前改变----当前页码改变之后，触发这个函数
       handleCurrentChange(val){
           console.log("当前改变："+val)
           this.currentPage = val
       }
   }
    }
</script>