<template>
    <div class="Case">



        <div class="News-wrap">
            <h3>专业游艇码头 制造商<br>Professional Floating Dock Manufacturer</h3>
            
        </div>





        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">案例中心</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>


        <section class="destination" id="destination">

            <div class="heading">
                <span>精美案例</span>
                <h1>为您带来更多的可能性</h1>
            </div>

            <div class="box-container">

                <div class="box" data-aos="fade-up" data-aos-delay="150" v-for="item in cases" :key="item.id">
                    <router-link :to="'/' + item.router">
                    <div class="image">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="content">
                        
                        <h3>项目名称：{{item.name}}</h3>
                        <h3>{{item.Ename}}</h3>
                        <p>时间/Time：{{item.date}}<br>地点/Add：{{item.location}}<br>案例/Projects</p>
                        <a href="#">了解更多<i class="fas fa-angle-right"></i></a>
                        
                    </div>
                    </router-link>
                </div>

                <!-- <div class="box" data-aos="fade-up" data-aos-delay="300">
                    <div class="image">
                        <img src="../assets/images/des-2.jpg" alt="">
                    </div>
                    <div class="content">
                        <h3>国外项目</h3>
                        <p>2019-9 <br>新喀里多尼亚烟花平台<br>案例</p>
                        <a href="#">了解更多<i class="fas fa-angle-right"></i></a>
                    </div>
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="450">
                    <div class="image">
                        <img src="../assets/images/des-3.jpg" alt="">
                    </div>
                    <div class="content">
                        <h3>国外项目</h3>
                        <p>2020-06<br>卡塔尔铝合金码头<br>案例</p>
                        <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="600">
                    <div class="image">
                        <img src="../assets/images/des-4.jpg" alt="">
                    </div>
                    <div class="content">
                        <h3>国外项目</h3>
                        <p>2018-10 <br>迪拜码头<br>案例</p>
                        <a href="#">了解更多<i class="fas fa-angle-right"></i></a>
                    </div>
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="750">
                    <div class="image">
                        <img src="../assets/images/des-5.jpg" alt="">
                    </div>
                    <div class="content">
                        <h3>国内案例</h3>
                        <p>2021-05<br>福建漳州沙滩网红冲浪浮桥<br>案例</p>
                        <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="900">
                    <div class="image">
                        <img src="../assets/images/des-6.jpg" alt="">
                    </div>
                    <div class="content">
                        <h3>国内案例</h3>
                        <p>2021-03 <br> 成都大邑浮动码头<br>案例</p>
                        <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="1150">
                    <div class="image">
                        <img src="../assets/images/des-7.jpg" alt="">
                    </div>
                    <div class="content">
                        <h3>国内案例</h3>
                        <p>2018-02<br>广州从化浮桥<br>案例</p>
                        <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="1300">
                    <div class="image">
                        <img src="../assets/images/des-8.jpg" alt="">
                    </div>
                    <div class="content">
                        <h3>国内案例</h3>
                        <p>2021-8<br>云南昆明凯悦酒店<br>案例</p>
                        <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                    </div>
                </div> -->

            </div>

        </section>

        <!-- banner section star-ts  -->

        <!-- <div class="banner">

            <div class="content" data-aos="zoom-in-up" data-aos-delay="300">
                <span>富升实业</span>
                <h3>专业浮动码头制造商</h3>
                <p>富升实业有限公司是一家专业从事于游艇码头，浮动码头，浮桥，浮动防波提，光伏浮筒及配套设施的企业，也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。本公司拥有一支从业经验超过10年的专业团队，积累了丰富的水上工程经验和技术。
                </p>
                <router-link to="/Contact"><a href="#book-form" class="btn">联系我们</a></router-link>
            </div>

        </div> -->

        <!-- banner section ends -->

    </div>
</template>

<script>
    export default {
        data() {
            return {
                cases: [{
                        id: "01",
                        img: require("../assets/Case/Marinas/1/2.jpg"),
                        name: "游艇码头",
                        Ename:"Aluminum/Steel Pontoon",
                        location: "国内外",
                        date: "2016-Now",
                        router: "Marina"
                    },
                    {
                        id: "02",
                        img: require("../assets/Case/Plastic/1/1.jpg"),
                        name: "塑料浮筒",
                        Ename:"Hdpe Modular Pontoon",
                        location: "国内外",
                        date: "2016-Now",
                        router: "Plasticfloat"
                    },
                    {
                        id: "03",
                        img: require("../assets/Case/Boat/1/1.jpg"),
                        name: "船艇泊位",
                        Ename:"Boat Berth",
                        location: "国内外",
                        date: "2016-Now",
                        router: "Boat"
                    },
                    {
                        id: "04",
                        img: require("../assets/Case/Float/1/1.jpg"),
                        name: "光伏浮筒",
                        Ename:"Floating Solar Plants",
                        location: "国内外",
                        date: "2016-Now",
                        router: "Photovoltaic"
                    },
                    {
                        id: "05",
                        img: require("../assets/img/barrier.jpg"),
                        name: "防浪堤",
                        Ename:"Floating Breakwater",
                        location: "国内外",
                        date: "2016-Now",
                        router: "Breakwater"
                    },
                    {
                        id: "06",
                        img: require("../assets/Case/Ocean/1/1.jpg"),
                        name: "海洋渔排",
                        Ename:"Deep Sea Fishing Cages",
                        location: "国内外",
                        date: "2016-Now",
                        router: "Ocean"
                    }
                ]
            }
        }
    }
</script>

<style>
    .Case .Case_item {
        display: inline-block;
        width: 32%;
        height: 450px;
        border: 1px solid #e0e0e0;
        /* box-shadow: 5px 5px 5px  #ffffff; */
        margin: 0 15px 1% 0;
        border-radius: 15px;
        overflow: hidden;
    }

    .Case_item a {
        color: black;
    }

    .Case .Case_item_img {
        width: 100%;
        height: 300px;
    }

    .Case .Case_item_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
        color:#000000 !important;
    }

    .Case .Case_item_text {
        width: 100%;
        height: 150px;
        line-height: 35px;
        background: #ffffff;
    }

    .Case .Case_item_text h3 {
        padding: 20px 0 0 20px;
        text-align: left;
        color: black;
    }

    .Case .Case_item_text h5 {
        text-align: left;
        padding-left: 65px;
        font-weight: normal;
        color: #000000;
    }

    .Case .Case_item_text p {
        text-align: right;
        padding-right: 20px;
        font-weight: normal;
        color: black;
    }

    .Case .box-container{
        margin: 0 auto;
        max-width: 1100px;
    }

    .navigation-wrap {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgb(189, 189, 189);
    }

    .navigation-bar {
        max-width: 1200px;
        margin: 0 auto;
    }

    /* .navigation-bar .navigation-left-bar {
        width: 40%;
    } */

    .navigation-bar p {
        float: left;
        line-height: 50px;
        font-size: 16px;
        color: #000;
    }

    .el-breadcrumb {
        float: left;
    }

    .el-breadcrumb__inner {
        font-size: 16px;
        line-height: 50px;
    }

    @media screen and (max-width:768px) {
        .Case .Case_item {
            margin: 15px 0;
            width: 100%;
        }
    }
</style>