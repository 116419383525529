<template>
    <div class="introduct">
        
        <div class="banner">

            <div class="content" data-aos="zoom-in-up" data-aos-delay="300">
                <span>富升实业（广州）有限公司</span>
                <h3>专业浮动码头制造商</h3>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;富升实业（广州）有限公司是一家专业从事于游艇码头，浮动码头，浮桥，<br>浮动防波提，光伏浮筒及配套设施的企业，<br>也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。<br>本公司拥有一支从业经验超过10年的专业团队，<br>积累了丰富的水上工程经验和技术。
                </p>

                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acrdock offers aluminium and steel pontoons, breakwaters, hdpe modular pontoons and other marina equipments.We group of marine engineers,designers and production specialists providing the best marine construction solutions for your needs on the water and shoreline.<br>Our fully integrated service model and design /build methodology provides a cost-effective, highly streamlined approach to every project.Acrdock provides customers with knowledge and expertise of design, manufacturing and installation, maintenance and modernization of marinas.</p>
               <router-link to="/Contact"><a href="#book-form" class="btn">联系我们/Contact us</a></router-link>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>