<template>
    <div class="m1">
        <div class="News-wrap">
            <h3>专业游艇码头 制造商<br>Professional Floating Dock Manufacturer</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">案例展示</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">案例</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item>案例一</el-breadcrumb-item> -->
                    </el-breadcrumb>
                </div>
            </div>

        </div>


        <div class="news_content" >
            <div class="news_content_box">
                <div class="content_title">
                    <h3>{{list.name}}</h3>
                </div>
                <div class="content_date">
                    <p>发布时间：{{list.date}}</p>
                    <span>案例：{{list.router}} 地点：{{list.location}}</span>
                </div>
            </div>

            <!-- <el-carousel class="moblie-display" :interval="10000" :height="bannerHeight + 'px'" trigger="click" loop
                indicator-position="outside" arrow="always">
                <el-carousel-item v-for="item in list.imgs" :key="item">
                    <img :src="'https://www.acrdock.com/uploads/file/'+item" alt="">
                </el-carousel-item>
            </el-carousel> -->

            <span style="color:red;font-size:18px">{{list.content}}</span>
            
            <section class="gallery" id="gallery">
                <div class="box-container">

                    <div class="box" v-for="item in list.imgs" :key="item.id">
                        <img :src="'https://www.acrdock.com/uploads/file/'+item" alt="">
                        <!-- <span>2020.06 </span>
                    <h3>澳大利亚-Tom快艇码头</h3> -->
                    </div>

                </div>

            </section>



            <div class="content_page">
                <router-link to="" class="last_page">上一篇：没有了</router-link>
                <router-link to="" class="next_page">下一篇：没有了</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                
                list: [],

                bannerHeight: 600,
                //图片父容器高度
                screenWidth: 0,
                //浏览器宽度
                interval: 2500
                //自动切换的时间间隔
            }
        },
        computed: {},
        mounted() {
            var id = this.$route.query.id;
            this.requestData(id);
            //首次加载时，调用一次
            this.screenWidth = window.innerWidth
            this.setSize()
            //窗口大小发生改变时，调用一次
            window.onresize = () => {
                this.screenWidth = window.innerWidth
                this.setSize()
            }
        },
        methods: {
            requestData(id) {
                var api = "https://www.acrdock.com/api/ytmt/getList?id=" + id;
                this.$http.get(api).then((res) => {
                        if (!res.data.data.list) {
                            throw new Error("报错")
                        }
                        // console.log(this.list)
                        this.list = res.data.data.list[0]
                        this.list.imgs = this.list.img.split(';')
                        // this.list.imgs = this.list.imgs.slice(0, this.list.imgs.length - 1)
                    },
                    (err) => {
                        console.log(err)
                    })
            },
            setSize: function () {
                //通过浏览器宽度（图片宽度）计算高度
                if (this.screenWidth < 768) {
                    this.bannerHeight = 350

                } else {
                    // this.bannerHeight = 605 / 1750 * this.screenWidth
                    this.bannerHeight = 665
                }
            },
            
        },


    }
</script>

<style>
    .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .mySwiper2 {
        height: 80%;
        width: 100%;
    }

    .mySwiper {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .mySwiper .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }

    .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .news_content {
        max-width: 1500px;
        margin: 15px auto;
    }

    /* .news_content .gallery {
        padding: 0;
    }

    .news_content .gallery .box-container {
        -moz-columns: 2 25rem;
        columns: 2 25rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
    } */

    .content_title {
        margin-bottom: 15px;
    }

    .content_title h3 {
        font-size: 32px;
        color: #000;
    }

    .news_content_text p {
        text-align: left;
        color: #000;
        font-size: 16px;
        line-height: 20px;
    }

    /* .news_content_text img{
        margin-bottom: 20px;
        object-fit: cover;
    } */

    .content_date {
        border-top: 1px dashed gray;
        border-bottom: 1px dashed gray;
        margin-bottom: 15px;
        /* height: 50px; */
    }

    .content_date p {
        font-size: 16px;
        color: #000;
        line-height: 30px;
    }

    .content_date span {
        padding-left: 20px;
        font-size: 14px;
        color: #000;
        line-height: 30px;
    }


    .content_page {
        width: 100%;
        height: 50px;
        border-top: 1px dashed gray;
        border-bottom: 1px dashed gray;
        line-height: 50px;
        margin-top: 30px;
        font-size: 14px;
    }

    .content_page .last_page {
        color: #000;
        float: left;
    }

    .content_page .next_page {
        color: #000;
        float: right;
    }

    .el-carousel__container img {
        height: inherit;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
        .moblie-display {
            display: none;
        }
    }
</style>