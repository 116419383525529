<template>
    <div class="Footer">
        <section class="footer">

            <div class="box-container">

                <div class="box" data-aos="fade-up" data-aos-delay="300">
                    <h3>首页导航</h3>
                    <router-link to="/Home"><a href="#home" class="links"> <i class="fas fa-arrow-right"></i> 首页/Home </a></router-link>
                    <router-link to="/Culture"><a href="#about" class="links"> <i class="fas fa-arrow-right"></i> 关于/About </a></router-link>
                    <router-link to="/Case"><a href="#destination" class="links"> <i class="fas fa-arrow-right"></i> 产品/Product</a></router-link>
                    <router-link to="/Case"><a href="#destination" class="links"> <i class="fas fa-arrow-right"></i> 案例/Project  </a></router-link>
                    <router-link to="/News"><a href="#blogs" class="links"> <i class="fas fa-arrow-right"></i> 新闻/News </a></router-link>
                    <router-link to="/Contact"><a href="#services" class="links"> <i class="fas fa-arrow-right"></i> 联系/Contact </a></router-link>
                    
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="450">
                    <h3>联系方式/Contact us</h3>
                    <!-- <p>AcrDock 富升实业</p> -->
                    <p><i class="fas fa-map"></i>地址: 广东省广州市南沙区海滨路185号822房 </p>
                    <!-- <p>Add: R822, No185, Haibin Road, Nansha District, Guangzhou, China</p> -->
                    <p><i class="fas fa-phone"></i>Tel: +86 189 2532 8565,  +86 189 2532 8685 </p>
                    <p><i class="fas fa-envelope"></i>Email:sales@acrdock.com,yannickhe@acrdock.com </p>
                    <p> <i class="fas fa-clock"></i> 8:00am - 10:00pm </p>
                </div>

                <div class="box" data-aos="fade-up" data-aos-delay="150">
                    <a href="#" class="logo"> <i class="fas fa-paper-plane"></i>AcrDock 富升实业（广州）有限公司</a>
                    <p>专业从事于水上游艇码头、帆船码头、赛艇码头、交通浮桥、景观平台、景观拱桥、水上隔离带等水上工程. 
                        能为客户提供项目咨询、工程报价、打桩、设计、制造及工程安装维修升级改造等专业化服务. </p>
                    <div class="share">
                        <a href="#"><img src="../assets/img/icon_wechat_hover.png" alt=""></a>
                        <a href="#"><img src="../assets/img/icon_weibo_hover.png" alt=""></a>
                    </div>
                    <p>© 2021 富升实业（广州）有限公司 ACRDOCK COPYRIGHT. 版权所有.</p>
                    <a href="https://beian.miit.gov.cn/"><p>粤ICP备2021131958号</p></a>
                </div>

            </div>

        </section>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>