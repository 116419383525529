<template>
    <div class="Home">
        <section class="about" id="about">

            <div class="video-container" data-aos="fade-right" data-aos-delay="300">
                <video poster="../assets/images/gallery-img-1.jpg" src="../assets/images/video.mp4" muted autoplay loop class="video"></video>
                <div class="controls">
                    <!-- <span class="control-btn" data-src="../assets/images/about-vid-1.mp4"></span> -->
                    <!-- <span class="control-btn" data-src="../assets/images/about-vid-2.mp4"></span>
                    <span class="control-btn" data-src="../assets/images/about-vid-3.mp4"></span> -->
                </div>
            </div>

            <div class="content" data-aos="fade-left" data-aos-delay="600">
                <span>专业浮动码头制造商</span>
                <h3>为什么选择我们?</h3>
                <p>富升实业（广州）有限公司是一家专业从事于游艇码头，浮动码头，浮桥，<br>浮动防波提，光伏浮筒及配套设施的企业，<br>也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。<br>本公司拥有一支从业经验超过10年的专业团队，<br>积累了丰富的水上工程经验和技术。
                </p>
                
                <h3>Why choose us?</h3>
                <p>Acrdock offers aluminium and steel pontoons, breakwaters, hdpe modular pontoons and other marina equipments.We group of marine engineers,designers and production specialists providing the best marine construction solutions for your needs on the water and shoreline.<br> Our fully integrated service model and design /build methodology provides a cost-effective, highly streamlined approach to every project.Acrdock provides customers with knowledge and expertise of design, manufacturing and installation, maintenance and modernization of marinas.</p>
               <router-link to="/Center"> <a href="#" class="btn">了解更多/More</a> </router-link>
            </div>

        </section>

        <!-- about section ends -->

        <!-- services section starts  -->

        <section class="services" id="services">            
            <div class="heading">
                
                <h1>产品/Products</h1>
                <span>专业团队为您服务</span>
            </div>

            <div class="box-container">
                
                <div class="box" data-aos="zoom-in-up" data-aos-delay="150" v-for="item in items" :key="item.id">
                    <router-link :to="'/' + item.router">
                    <div class="image">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="content">
                        <i class="fas fa-globe"></i>
                        <h3>{{item.nameC}}</h3>
                        <p>{{item.nameE}}</p>
                    </div>
                    </router-link>
                </div>

            </div>

        </section>
        <!-- services section ends -->

        <!-- destination section starts  -->

        <section class="destination" id="destination">

            <div class="heading">
                
                <h1>精美案例/Projects</h1>
                <span>为您带来更多的可能性</span>
            </div>

            <div class="box-container">

                <div class="box" data-aos="fade-up" data-aos-delay="150" v-for="item in cases" :key="item.id">
                    <router-link to="/Case">
                    <div class="image">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="content">
                        <h3>{{item.location}}</h3>
                        <p>{{item.date}}<br>{{item.name}}</p>
                        <a href="#">了解更多/More<i class="fas fa-angle-right"></i></a>
                    </div>
                    </router-link>
                </div>
            </div>

        </section>

        <!-- destination section ends -->

        
        <!-- gallery section starts  -->

        <section class="gallery" id="gallery">

            <div class="heading">
                <span>照片墙/Pictures</span>
                <h1>专业吹塑产品设计生产商</h1>
            </div>

            <div class="box-container">

                <div class="box" data-aos="zoom-in-up" data-aos-delay="150" v-for="list in gallerys" :key="list.id">
                    <img :src="list.image" alt="">
                    <span>{{list.date}} </span>
                    <h3>{{list.name}}</h3>
                </div>

            </div>

        </section>

        <!-- gallery section ends -->

        <!-- blogs section starts  -->

        <section class="blogs" id="blogs">

            <div class="heading">
                
                <h1>案例新闻/News</h1>
                <span>用心服务好每一位客户</span>
            </div>

            <div class="box-container">

                <div class="box" data-aos="fade-up" data-aos-delay="150" v-for="list in news" :key="list.id">
                    <router-link to="/News">
                    <div class="image">
                        <img :src="list.img" alt="">
                    </div>
                    <div class="content">
                        <a href="#" class="link">{{list.name}}</a>
                        <p>{{list.location}}</p>
                        <div class="icon">
                            <a href="#"><i class="fas fa-clock"></i> {{list.date}}</a>
                            <a href="#"><i class="fas fa-user"></i> 富升实业（广州）有限公司</a>
                        </div>
                    </div>
                    </router-link>
                </div>
            </div>

        </section>

        <!-- blogs section ends -->

        <!-- banner section star-ts  -->

        <!-- <div class="banner">

            <div class="content" data-aos="zoom-in-up" data-aos-delay="300">
                <span>富升实业（广州）有限公司</span>
                <h3>专业浮动码头制造商</h3>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;富升实业（广州）有限公司是一家专业从事于游艇码头，浮动码头，浮桥，<br>浮动防波提，光伏浮筒及配套设施的企业，<br>也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。<br>本公司拥有一支从业经验超过10年的专业团队，<br>积累了丰富的水上工程经验和技术。
                </p>
                <br>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acrdock offers aluminium and steel pontoons, breakwaters, hdpe modular pontoons and other marina equipments.We group of marine engineers,designers and production specialists providing the best marine construction solutions for your needs on the water and shoreline.<br>Our fully integrated service model and design /build methodology provides a cost-effective, highly streamlined approach to every project.Acrdock provides customers with knowledge and expertise of design, manufacturing and installation, maintenance and modernization of marinas.</p>
               <router-link to="/Contact"><a href="#book-form" class="btn">联系我们/Contact us</a></router-link>
            </div>

        </div> -->

        <!-- banner section ends -->
    </div>
</template>

<script>
    export default {
        data() {
            return{
                items:[
                    {
                        id:'01',
                        img:require('../assets/Case/Marinas/1/2.jpg'),
                        nameC:'游艇码头',
                        nameE:'Aluminum/Steel Pontoon',
                        router: "P_1"
                    },
                    {
                        id:'02',
                        img:require('../assets/images/blog-1.jpg'),
                        nameC:'塑料浮筒',
                        nameE:'Hdpe Modular Pontoon',
                        router: "P_2"
                    },
                    {
                        id:'03',
                        img:require('../assets/img/product3.jpg'),
                        nameC:'船艇泊位',
                        nameE:'Boat Berth',
                        router: "P_3"
                    },
                    {
                        id:'04',
                        img:require('../assets/Case/Float/1/3.jpg'),
                        nameC:'光伏浮筒',
                        nameE:'Floating Solar Plants',
                        router: "P_4"
                    },
                    {
                        id:'05',
                        img:require('../assets/img/fang.jpg'),
                        nameC:'防浪堤',
                        nameE:' Floating Breakwater',
                        router: "P_5"
                    },
                    {
                        id:'06',
                        img:require('../assets/Case/Ocean/1/1.jpg'),
                        nameC:'海洋渔排',
                        nameE:'Deep Sea Fishing Cages',
                        router: "P_6"
                    }
                ],
                cases:[
                    {
                        id:'01',
                        img:require('../assets/images/des-1.jpg'),
                        date:'2016-10',
                        name:'印度水上酒店',
                        location:'国外项目/Projects'
                    },
                    {
                        id:'02',
                        img:require('../assets/images/des-2.jpg'),
                        date:'2019-09',
                        name:'新喀里多尼亚烟花平台',
                        location:'国外项目/Projects'
                    },
                    {
                        id:'03',
                        img:require('../assets/images/des-3.jpg'),
                        date:'2020-06',
                        name:'卡塔尔铝合金码头',
                        location:'国外项目/Projects'
                    },
                    {
                        id:'04',
                        img:require('../assets/images/des-4.jpg'),
                        date:'2018-10',
                        name:'迪拜码头',
                        location:'国外项目/Projects'
                    },
                    {
                        id:'05',
                        img:require('../assets/images/des-5.jpg'),
                        date:'2021-05',
                        name:'福建漳州沙滩网红冲浪浮桥',
                        location:'国内项目/Projects'
                    },
                    {
                        id:'06',
                        img:require('../assets/images/des-6.jpg'),
                        date:'2021-03',
                        name:'成都大邑浮动码头',
                        location:'国内项目/Projects'
                    },
                    {
                        id:'07',
                        img:require('../assets/images/des-7.jpg'),
                        date:'2018-02',
                        name:'广州从化浮桥',
                        location:'国内项目/Projects'
                    },
                    {
                        id:'08',
                        img:require('../assets/images/des-8.jpg'),
                        date:'2021-08',
                        name:'云南昆明凯悦酒店',
                        location:'国内项目/Projects'
                    },
                ],
                gallerys:[
                    {
                        id:'001',
                        image:require('../assets/images/gallery-img-1.jpg'),
                        date:'2020.06',
                        name:'澳大利亚-Tom快艇码头'
                    },
                    {
                        id:'002',
                        image:require('../assets/images/gallery-img-2.jpg'),
                        date:'2021-05',
                        name:'深圳较场尾-浮桥平台'
                    },
                    {
                        id:'003',
                        image:require('../assets/images/gallery-img-3.jpg'),
                        date:'2014-03',
                        name:'印度-Tehri浮动码头'
                    },
                    {
                        id:'004',
                        image:require('../assets/images/gallery-img-4.jpg'),
                        date:'2021.03',
                        name:'美国-Brian浮桥'
                    },
                    {
                        id:'005',
                        image:require('../assets/images/gallery-img-5.jpg'),
                        date:'2020.10',
                        name:'新疆赛里木湖风景区-铝合金码头'
                    },
                    {
                        id:'006',
                        image:require('../assets/images/gallery-img-6.jpg'),
                        date:'2021-01',
                        name:'海南乐东县-浮桥码头'
                    },
                    {
                        id:'007',
                        image:require('../assets/images/gallery-img-7.jpg'),
                        date:'2020-08',
                        name:'重庆云阳-清水湖水上浮桥'
                    },
                    {
                        id:'008',
                        image:require('../assets/images/gallery-img-8.jpg'),
                        date:'2021-06',
                        name:'湖南长沙-石燕湖水上乐园'
                    },
                    {
                        id:'009',
                        image:require('../assets/images/gallery-img-9.jpg'),
                        date:'2020-06',
                        name:'卡塔尔-铝合金码头'
                    },

                ],
                news:[
                    {
                        id:'01',
                        img:require('../assets/images/blog-1.jpg'),
                        name:'游艇码头',
                        location:'成都迪佤-游艇码头',
                        date:'2020-06'
                    },
                    {
                        id:'02',
                        img:require('../assets/images/blog-2.jpg'),
                        name:'水上救援平台',
                        location:'泰国曼谷-水上救援平台',
                        date:'2019-08'
                    },
                    {
                        id:'03',
                        img:require('../assets/images/blog-3.jpg'),
                        name:'水上舞台',
                        location:'广西梧州-水上舞台',
                        date:'2019-11'
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>