<template>
    <div class="product-1">
        <div class="News-wrap">
            <h3>专业游艇码头 制造商</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">产品</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="title-wrap">
            <div class="title">
                <h3>Floating Breakwater<br>浮动防波堤</h3>
            </div>
            <div class="img-wrap">
                <img src="../assets/Product/1.jpg" alt="">
            </div>
            <div class="content-wrap">
                <h2>Floating Breakwater<br>
                    浮动防波堤<br><br></h2>

                <p>浮动防波堤<br>
                    通常由一系列单独的浮筒组成，这些浮筒通过钢缆或其他装置
                    连接在一起。这些连接件会不断移动，可能导致疲劳损坏和连接件失效。
                    浮动防波堤的金属部件也容易受到腐蚀。构成防波堤的检测技术也会受到
                    恶劣天气的影响，这也会影响其性能。<br><br>

                    Floating breakwater usually consist of a line of individual floating elements
                    connected by cables or other devices. These connections are subject to continuous movement, possibly
                    resulting in fatigue damage and failure of the connections. Metal components of a breakwater system
                    are subject to corrosion. The
                    detection technologies that comprise the waterside breakwater system are also
                    subject to the harshness of weather which can affect performance.<br><br>

                    所有浮动防波堤的系泊配件都必须定期检查，不管是正在使用中的还
                    是库存中的，以确保其当前的材料状况和未来的维护要求。这些检查
                    的重要性不能过分强调，因为任何维护计画的有效性总是取决于这些
                    检查和服务的执行频率和程度。因此，检查计画应考虑检查频率的关
                    键因素以及工作的彻底性、完整性和品质。<br><br>

                    All floating breakwater mooring components, either in use or in storage,
                    must be periodically inspected to determine their current material condition
                    and their future maintenance requirements. The importance of these
                    inspections cannot be overemphasized because the effectiveness of any
                    maintenance program will always depend on how often and how well
                    these checks and services are performed. Inspection plans, therefore,
                    should take into consideration the critical elements of frequency of inspections and the
                    thoroughness, completeness, and quality of work.<br><br>

                </p>
            </div>
            <section class="gallery" id="gallery">
                <div class="box-container">

                    <div class="box" v-for="item in items" :key="item.id">
                        <img :src="item.img" alt="">
                        <!-- <span>2020.06 </span>
                    <h3>澳大利亚-Tom快艇码头</h3> -->
                    </div>

                </div>

            </section>

        </div>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [{
                        id: '01',
                        img: require('../assets/Case/Break/2/1.jpg')
                    },
                    {
                        id: '02',
                        img: require('../assets/Case/Break/2/2.jpg')
                    },
                    {
                        id: '03',
                        img: require('../assets/Case/Break/2/3.jpg')
                    },

                ],
            }
        },
    }
</script>

<style src='../assets/css/Product.css'>

</style>