import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
// import BaiduMap from 'vue-baidu-map'
import './assets/css/style.css'
import VueRource from 'vue-resource'
import axios from 'axios';
// import Swiper from 'swiper'
// import '../../node_modules/swiper/swiper-bundle.min.css'


// import './assets/css/all.min.css'
// import './assets/css/aos.css'
// import './assets/css/css2.css'
// import './assets/css/style.scss'
// import './assets/js/aos.js'
// import './assets/js/script'


axios.defaults.timeout = 60 * 1000;

Vue.prototype.$axios=axios
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueRource)
// Vue.use(Swiper)
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: '34BZZjjxzPHUoGkGUzdTckYEMdwcwymC'
// })

new Vue({
  render: h => h(App),
  router
}).$mount('#app')