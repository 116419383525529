<template>
  <div id="app">
    <home-header></home-header>
    <router-view></router-view>
    <home-introduct></home-introduct>
    <home-footer></home-footer>
  </div>
</template>

<script>
  import HomeHeader from './components/Header'
  import HomeIntroduct from './components/Introduct.vue'
  import HomeFooter from './components/Footer'
import Home from './components/Home.vue'
  export default {
    name: 'Home',
    components: {
      HomeHeader,
      HomeIntroduct,
      HomeFooter
    }
  }

        Home</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    /* margin-top: 60px; */
  }
</style>