<template>
    <div class="News">
        <div class="News-wrap">
            <h3>专业游艇码头 制造商<br>Professional Floating Dock Manufacturer</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="News_wrap">
            <!-- //移动端新闻列表 -->
            <div class="News_box">
                <router-link to="/n1">
                    <div class="News_text">
                        <div class="News_text_box">
                            <h2>新闻标题</h2>
                            <p>为落实国务院关于“加大协定实施相关培训力度”的重要部署，3月22-23日，商务部举办《区域全面经济伙伴关系协定》（RCEP）第二次线上专题培训。培训聚焦RCEP对产业影响，帮助地方和企业熟悉协定内容和规则，提升管理水平和政策利用能力，增强参与国际合作竞争的本领。
                            </p>
                            <h5>2021-07-21</h5>
                        </div>
                    </div>
                    <div class="News_img">
                        <img src="../assets/images/blog-1.jpg" alt="">
                    </div>
                </router-link>
            </div>

            <!-- //PC新闻列表 -->
            <router-link to="/n1">
                <div class="News_list">

                    <div class="News_date_box">
                        <h3>2021</h3>
                        <p>08-01</p>
                    </div>
                    <div class="News_txt">
                        <h2>新闻标题</h2>
                        <p>为落实国务院关于“加大协定实施相关培训力度”的重要部署，3月22-23日，商务部举办《区域全面经济伙伴关系协定》（RCEP）第二次线上专题培训。培训......</p>
                    </div>
                    <div class="News_img_box">
                        <img src="../assets/images/blog-1.jpg" alt="">
                    </div>
                </div>
            </router-link>

        </div>

        <el-pagination background layout="prev, pager, next" :total="10">
        </el-pagination>

        <div class="Case_wrap">
            <section class="destination" id="destination">

                <div class="heading">
                    <span>精美案例</span>
                    <h1>为您带来更多的可能性</h1>
                </div>

                <div class="box-container">


                    <div class="box" data-aos="fade-up" data-aos-delay="450">
                        <router-link to="/Case">
                            <div class="image">
                                <img src="../assets/images/des-3.jpg" alt="">
                            </div>
                            <div class="content">
                                <h3>国外项目</h3>
                                <p>2020-06<br>卡塔尔铝合金码头<br>案例</p>
                                <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                            </div>
                        </router-link>
                    </div>

                    <div class="box" data-aos="fade-up" data-aos-delay="600">
                        <router-link to="/Case">
                            <div class="image">
                                <img src="../assets/images/des-4.jpg" alt="">
                            </div>
                            <div class="content">
                                <h3>国外项目</h3>
                                <p>2018-10 <br>迪拜码头<br>案例</p>
                                <a href="#">了解更多<i class="fas fa-angle-right"></i></a>
                            </div>
                        </router-link>
                    </div>

                    <div class="box" data-aos="fade-up" data-aos-delay="750">
                        <router-link to="/Case">
                            <div class="image">
                                <img src="../assets/images/des-5.jpg" alt="">
                            </div>
                            <div class="content">
                                <h3>国内案例</h3>
                                <p>2021-05<br>福建漳州沙滩网红冲浪浮桥<br>案例</p>
                                <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                            </div>
                        </router-link>
                    </div>



                </div>

            </section>
        </div>

        <!-- banner section star-ts  -->
<!-- 
        <div class="banner">

            <div class="content" data-aos="zoom-in-up" data-aos-delay="300">
                <span>富升实业（广州）有限公司</span>
                <h3>专业浮动码头制造商</h3>
                <p>经祥港口设施工程有限公司是一家专业从事于游艇码头，浮动码头，浮桥，浮动防波提，光伏浮筒及配套设施的企业，也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。本公司拥有一支从业经验超过10年的专业团队，积累了丰富的水上工程经验和技术。
                </p>
               <router-link to="/Contact"> <a href="#book-form" class="btn">联系我们</a> </router-link>
            </div>

        </div> -->

        <!-- banner section ends -->

    </div>
</template>

<script>
    export default {

    }
</script>

<style>
    .News a {
        color: white;
    }

    .News-wrap {
        background: url('../assets/images/home-bg2.jpg');
        height: 350px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        /* background-position: center; */
        position: relative;
    }

    .News-wrap h3 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 38px;
    }

    .News_wrap {
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
    }

    .News_list {
        width: 80%;
        margin: 30px auto;
        height: 180px;
        border: 1px solid rgb(214, 214, 214);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transition: all .2s linear;
        cursor: pointer;
    }

    .News_list:hover {
        width: 79%;
        box-shadow: 5px 5px 5px 5px #868686;
    }

    .News_date_box {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .News_date_box h3 {
        font-size: 36px;
        color: #000;
    }

    .News_date_box p {
        font-size: 20px;
        font-weight: bold;
        color: #000;
    }

    .News_txt {
        display: flex;
        /* align-items: center; */
        justify-content: center;
        flex-direction: column;
        text-align: left;
    }

    .News_txt h2 {
        padding-bottom: 15px;
        color: #000;
        font-size: 16px;
    }

    .News_txt p {
        color: #000;
        font-size: 12px;
    }

    .News_img_box {
        width: 40%;
        height: 100%;
        padding: 10px;
    }

    .News_img_box img {
        width: 100%;
        height: 100%;

    }

    .News_box {
        width: 80%;
        margin: auto;
        position: relative;
        display: none;
    }

    .News_box:hover .News_text_box {
        transform: translate(5px, -5px);
    }

    .News_box:hover .News_text_box p {
        color: rgb(255, 209, 83);
    }

    .News_text {
        width: 500px;
        /* height: 330px; */
        /* background: rgb(253, 253, 253); */
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-50%);
    }

    .News_text2 {
        width: 500px;
        /* height: 330px; */
        background: rgb(253, 253, 253);
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
    }

    .News_text_box {
        text-align: left;
        padding: 88px 64px;
        transition: all .3s;
    }

    .News_text_box h2 {
        font-size: 16px;
        color: #000;
    }

    .News_text_box p {
        padding-top: 20px;
        font-size: 14px;
        color: #000;
    }

    .News_text_box h5 {
        margin-top: 20px;
        text-align: right;
        /* font-size: 14px; */
        /* color: #666666; */
    }

    .News_img {
        margin-top: 50px;
        width: 100%;
        height: 450px;
        overflow: hidden;
    }

    .News_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .Case_wrap {
        max-width: 1200px;
        margin: auto;
    }

    .Case_wrap .text-title {
        margin: 35px 0;
        /* height: 30px; */
    }

    .Case_wrap .text-title h2 {
        position: relative;
        font-size: 30px;
        padding-bottom: 5px;
        color: #000;
    }

    .Case_wrap .text-title h2::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 2px;
        background: rgb(180, 63, 17);
    }

    .Case_item {
        display: inline-block;
        width: 32%;
        height: 450px;
        border: 1px solid #e0e0e0;
        /* box-shadow: 5px 5px 10px 1px #e0e0e0; */

    }

    .Case_item2 {
        display: inline-block;
        width: 32%;
        margin: 20px 2%;
        height: 450px;
        border: 1px solid #e0e0e0;
        box-shadow: 5px 5px 10px 1px #e0e0e0;
    }

    .Case_item_img {
        width: 100%;
        height: 300px;
    }

    .Case_item_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .Case_item_text {
        width: 100%;
        height: 150px;
        line-height: 35px;
        background: #f5f5f552;
    }

    .Case_item_text h3 {
        font-size: 18px;
        padding: 20px 0 0 20px;
        text-align: left;
    }

    .Case_item_text h5 {
        font-size: 12px;
        text-align: left;
        padding-left: 65px;
        color: #ffffff;
    }

    .Case_item_text p {
        font-size: 16px;
        text-align: right;
        padding-right: 20px;
        font-weight: bold;
        color: rgb(255, 255, 255);
    }

    .News section {
        padding: 3rem 9%;
    }

    @media screen and (max-width:768px) {
        .News-wrap h3 {
            width: 100%;
        }

        .News_list {
            display: none;
        }

        .News_box {
            width: 90%;
            margin-top: 35px;
            display: block;
        }

        .News_text {
            width: 100%;
            position: static;
            transform: none;
        }

        .News_text2 {
            width: 100%;
            position: static;
            transform: none;
        }

        .News_text_box {
            padding: 0;
        }

        .News_img {
            margin-top: 15px;
            height: 200px;
        }

        .Case_wrap {
            margin: 15px;
        }

        .Case_item {
            margin: 15px 0;
            width: 100%;
        }

        .Case_item2 {
            width: 100%;
            margin: 0;
        }
    }
</style>