<template>
    <div class="Company">

        <div class="culture_bg">
            <div class="culture-box">
                <div class="culture-title">
                    <h3>公司介绍/<p style="display:inline-block;">Company Introduction</p></h3>
                    <p>AcrDock是一家专门从事于水上游艇码头、帆船码头、赛艇码头、交通浮桥、景观平台、景观拱桥、水上隔离带等水上工程，能为客户提供项目咨询、工程报价、打桩、设计、制造及工程安装维修升级改造等专业化服务。
                    <br>
                                        Acrdock has more than 12 years of experience and is constantly expanding . Our team offers expert consulting, design, manufacturing and construction services.Our technical and production expertise in the field of floating docks enables us to minimize the cost and duration of the manufacturing process while maintaining the quality and safety of our products.
                    </p>
                    <!-- <h3>Company Introduction</h3> -->

                   

                </div>
                <div class="culture_img_box">
                    <p>十二年 <br>专注水上工程 <br>你值得信赖</p>
                    <div class="culture_img">
                        <img src="../assets/images/blog-1.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="culture_company">
            <div class="company_text">
                <h3>业务范围/<p>Business Scope</p></h3>
                <p>本公司已有十二年专业工作经验<br>对于水上浮码头工程，水上钢、铝结构专业加工及施工<br>水利土建工程保护所需水上隔离带等等.....</p>
                <!-- <h3>Business Scope</h3> -->
                <p>Acrdock offers aluminium and steel pontoons, breakwaters, hdpe modular pontoons and other marina equipments</p>
            </div>
            <div class="company_img">
                <div class="company_img1">
                    <img src="../assets/images/des-1.jpg" alt="">
                </div>
                <div class="company_img2">
                    <img src="../assets/images/des-2.jpg" alt="">
                </div>
            </div>
        </div>

        <div class="company_show">
            <h2 class="show_text">十二年 专业经验</h2>
            <h3>
            <p>你值得信赖 </p>
            <p>Built to Endure the Test of Time</p>
            </h3>
            
            <div class="show_img">
                <div class="show_title">
                    <div class="show_title_box">
                        <h3>12</h3>
                        <span>年/years<br>专业经验<br>Professional experience</span>
                    </div>
                    <div class="show_title_box">
                        <h3>500+</h3>
                        <span>项目/Project <br> 专业方案<br>Professional Design</span>
                    </div>
                    <div class="show_title_box">
                        <h3>10000+</h3>
                        <span>客户/Client<br> 首选公司 <br>The first choice in pontoon supply</span>
                    </div>
                </div>
                <div class="show_img_box">
                    <div class="experience__overlay">
                        <img src="../assets/images/gallery-img-6.jpg" alt="" class="experience__img-one">
                    </div>

                    <div class="experience__overlay">
                        <img src="../assets/images/gallery-img-4.jpg" alt="" class="experience__img-two">
                    </div>
                </div>
            </div>
        </div>

        <!-- banner section star-ts  -->

        <!-- <div class="banner">

            <div class="content" data-aos="zoom-in-up" data-aos-delay="300">
                <span>富升实业</span>
                <h3>专业浮动码头制造商</h3>
                <p>富升实业有限公司是一家专业从事于游艇码头，浮动码头，浮桥，浮动防波提，光伏浮筒及配套设施的企业，也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。本公司拥有一支从业经验超过10年的专业团队，积累了丰富的水上工程经验和技术。
                </p>
               <router-link to="/Contact"> <a href="#book-form" class="btn">联系我们</a> </router-link>
            </div>

        </div> -->

        <!-- banner section ends -->
    </div>

</template>

<script>
    export default {

    }
</script>

<style>
    .Culture {
        width: 100%;
    }

    .culture-wrap {
        width: 100%;
    }

    .culture_bg {
        width: 100%;
        height: 600px;
        background: url('../assets/img/1.png');
        background-size: cover;
        background-position: center;

    }

    .culture-box {
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        position: relative;
    }

    .culture-title {
        width: 480px;
        height: 380px;
        text-align: left;
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
    }

    .culture-title h3 {
        /* color: #000; */
        font-size: 28px;
    }

    .culture-title p {
        /* color: #000; */
        /* font-weight: bold; */
        font-size: 18px;
        margin-top: 20px;
    }

    .culture_img_box {
        width: 400px;
        height: 200px;
        background: rgb(53, 126, 131);
        position: absolute;
        right: 0;
        bottom: 50px;
    }

    .culture_img_box p {
        width: 120px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        line-height: 30px;
        text-align: left;
        left: 15px;
    }

    .culture_img {
        width: 230px;
        height: 160px;
        overflow: hidden;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .culture_img img {
        width: 100%;
        height: 100%;
        transition: all .2s linear;
    }

    .culture_img_box:hover .culture_img img {
        transform: scale(1.2);
    }

    .culture_company {
        max-width: 1100px;
        margin: 0 auto;
        height: 600px;
        position: relative;
    }

    .company_text {
        width: 40%;
        text-align: left;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .company_text h3 {
        color: #000;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .company_text p {
        font-size: 16px;
        line-height: 25px;
        /* font-weight: bold; */
        color: #222;
        display: inline-block;
    }

    .company_img {
        width: 60%;
        height: 100%;
        position: relative;
        float: right;
    }

    .company_img1 {
        width: 45%;
        height: 65%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        overflow: hidden;
    }

    .company_img1 img {
        width: 100%;
        height: 100%;
        transition: all .2s linear;
        object-fit: cover;
    }

    .company_img1:hover img {
        transform: scale(1.2);
    }

    .company_img2 {
        width: 50%;
        height: 80%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        overflow: hidden;
    }

    .company_img2 img {
        width: 100%;
        height: 100%;
        transition: all .2s linear;
        object-fit: cover;
    }

    .company_img2:hover img {
        transform: scale(1.2);
    }

    .company_show {
        max-width: 1200px;
        /* height: 600px; */
        margin: 0 auto;
    }

    .company_show h3{
        color: #000;
        font-weight: normal;
        font-size: 16px;
    }

    .show_title {
        display: flex;
        justify-content: space-between;
        /* width: 400px; */
        margin: 50px auto;
        text-align: left;
    }

    .show_title_box h3 {
        color: #000;
        font-size: 28px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }

    .show_text {
        color: #000;
        font-size: 28px;
    }

    .show_title_box span {
        font-size: 16px;
        color: #222;
    }

    .show_img_box {
        width: 650px;
        height: 550px;
        margin: 0 auto;
        position: relative;
    }

    .experience__overlay:nth-child(1) {
        width: 80%;
        height: 60%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
    }

    .experience__overlay:nth-child(2) {
        width: 40%;
        height: 70%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
    }

    .experience__overlay img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .2s linear;
    }

    .experience__overlay:hover img {
        transform: scale(1.2);
    }



    @media screen and (max-width:768px) {
        .culture_bg {
            width: 100%;
        }

        .culture-title {
            margin: 15px;
        }

        .culture-title h3 {
            font-size: 26px;
        }

        .culture-title p {
            font-size: 14px;
            font-weight: normal;
        }

        .culture_img_box {
            display: none;
            width: 340px;
            height: 160px;
        }

        .culture_img_box p {
            width: 100px;
            color: white;
            font-size: 16px;
            font-weight: bold;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            line-height: 30px;
            text-align: left;
            left: 15px;
        }

        .culture_img {
            width: 200px;
            height: 120px;
        }

        .culture_company {
            margin: 15px;
            height: 600px;
        }

        .show_title_box{
            margin: 0 8px;
        }

        .company_text {
            position: static;
            width: 100%;
            transform: none;
            text-align: center;
        }

        .company_img {
            width: 345px;
            height: 350px;
            margin: auto;
            float: none;
        }

        .company_img1 {
            width: 155.25px;
            height: 195px;
        }

        .company_img2 {
            width: 172.5px;
            height: 240px;
        }

        .culture-title {
            width: 80%;
            height: 300px;
            top: 15%;
            transform: none;
        }

        .company_show {
            margin: 15px;
        }

        .show_title {
            width: 345px;
        }

        .show_img_box {
            width: 345px;
            height: 300px;
        }

        .experience__overlay:nth-child(1) {
            height: 180px;
            width: 276px;
        }

        .experience__overlay:nth-child(2) {
            height: 210px;
            width: 138px;
        }
    }
</style>