<template>
    <div class="product-1">
        <div class="News-wrap">
            <h3>专业游艇码头 制造商</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">产品</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="title-wrap">
            <div class="title">
                <h3>HDPE MODULAR PONTOON<br>塑料浮筒码头</h3>
            </div>
            <div class="img-wrap">
                <img src="../assets/Product/7.jpg" alt="">
            </div>
            <div class="content-wrap">
                <h2>HDPE MODULAR PONTOON<br>
                    塑料浮筒码头<br><br></h2>

                <p>浮筒特点<br>
                    1.安全稳固：浮筒表面防滑设计，使人免于一般木制设施常见的危险。<br><br>
                    2.高承载力：每平方米可承载2000kg压力不变形。<br><br>
                    3.使用年限：根据不同的使用条件年限为10-25年。<br><br>
                    4.多用途：可广泛运用于各种领域。<br><br>
                    5.经济实惠：保养维修费用少。<br><br>
                    6.搭拆简便：可快速且简便的安装与拆卸。<br><br>
                    7.强耐性：防紫外线、防冻、抗海水、化学药剂、油渍等之侵触 。<br><br>
                    8.机动可变性：可依需要而变化，组合成任何尺寸和任何结构的设施。<br><br>
                    9.环保：原材料为高密度聚酯乙烯（HDPE），100%可回收。<br><br>
                    10.高强度：浮筒耳环对角拉力测试为1700kg以上，测试速度为50mm/min。<br><br><br>
                    FEATURES<br>
                    1.Safety: with patterns on the top surface， Modular Float System is free from the dangers
                    caused by slippery surfaces , splinters and rusty nails usually found on wooden walkways.
                    Also it has no sharp corners and is stable enough to walk on.<br><br>
                    2.High Compression：Average compression load is over 2000kg per square meter.<br><br>
                    3.Lifespan:10-25 years depends on different location conditions.<br><br>
                    4.Versatility:can be used widely in various fields.<br><br>
                    5.Economy:free maintenance and low upkeep.<br><br>
                    6.Convenience/Simplicity of Construction： can be easily quickly installed and dismantled.<br><br>
                    7. Durability:resistant to UV rays, frost, seawater,corrosion,chemicals, and oil.<br><br>
                    8.Changeability /Resolvability:one connecting pin holds four modules together-permitting<br><br>
                    the assembly of any size and any configuration on the water and<br><br>
                    the assembly can be changed to meet the requirements.<br><br>
                    9.Recycle: HDPE Material，100% recyclable，environmentally friendly.<br><br>
                    10.Strength:Diagonal lugs’ tensile strength is over 1700kg approved by<br><br>
                    the official authorities;testing speed is 50mm/min.</p>
            </div>
            <section class="gallery" id="gallery">
                <div class="box-container">

                    <div class="box" v-for="item in items" :key="item.id">
                        <img :src="item.img" alt="">
                        <!-- <span>2020.06 </span>
                    <h3>澳大利亚-Tom快艇码头</h3> -->
                    </div>

                </div>

            </section>

        </div>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [{
                        id: '01',
                        img: require('../assets/Case/Plastic/2/1.jpg')
                    },
                    
                    {
                        id: '04',
                        img: require('../assets/Case/Plastic/2/2.jpg')
                    },
                    {
                        id: '02',
                        img: require('../assets/Case/Plastic/2/3.jpg')
                    },
                    
                    {
                        id: '05',
                        img: require('../assets/Case/Plastic/2/4.jpg')
                    },
                    {
                        id: '03',
                        img: require('../assets/Case/Plastic/2/5.jpg')
                    },
                    {
                        id: '06',
                        img: require('../assets/Case/Plastic/2/6.jpg')
                    }

                ],
            }
        },
    }
</script>

<style src='../assets/css/Product.css'>

</style>