import Vue from 'vue'
import Router from 'vue-router'


// 导航栏
import Home from '../components/Home.vue'
import Center from '../components/Center.vue'
import Contact from '../components/Contact.vue'
import Culture from '../components/Culture.vue'
import Case from '../components/Case.vue'
import News from '../components/News.vue'

// 产品页
import P_1 from '../Product/Marina.vue'
import P_2 from '../Product/Plastic.vue'
import P_3 from '../Product/Boat.vue'
import P_4 from '../Product/Float.vue'
import P_5 from '../Product/Break.vue'
import P_6 from '../Product/Raft.vue'

// 案例
import Marina from '../List/Marina.vue'
import Plasticfloat from '../List/Plastic Float.vue'
import Boat from '../List/Boat Berth.vue'
import Photovoltaic from '../List/Photovoltaic Float.vue'
import Breakwater from '../List/Breakwater.vue'
import Ocean from '../List/Ocean Fishing Raft.vue'

// 案例页
import Page from '../Case/Page.vue'

// 新闻页
import n1 from '../News/news1.vue'

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/Home',
            redirect: '/'
        },
        {
            path: '/Center',
            name: 'Center',
            component: Center
        },
        {
            path: '/News',
            name: 'News',
            component: News
        },
        {
            path: '/Case',
            name: 'Case',
            component: Case
        },
        {
            path: '/Contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/Culture',
            name: 'Culture',
            component: Culture
        },

        // 案例
        {
            path: '/Marina',
            name: 'Marina',
            component: Marina
        },
        {
            path: '/Plasticfloat',
            name: 'Plasticfloat',
            component: Plasticfloat
        },
        {
            path: '/Boat',
            name: 'Boat',
            component: Boat
        },
        {
            path: '/Photovoltaic',
            name: 'Photovoltaic',
            component: Photovoltaic
        },
        {
            path: '/Breakwater',
            name: 'Breakwater',
            component: Breakwater
        },
        {
            path: '/Ocean',
            name: 'Ocean',
            component: Ocean
        },

        // 产品页
        {
            path: '/P_1',
            name: 'P_1',
            component: P_1
        },
        {
            path: '/P_2',
            name: 'P_2',
            component: P_2
        },
        {
            path: '/P_3',
            name: 'P_3',
            component: P_3
        },
        {
            path: '/P_4',
            name: 'P_4',
            component: P_4
        },
        {
            path: '/P_5',
            name: 'P_5',
            component: P_5
        },
        {
            path: '/P_6',
            name: 'P_6',
            component: P_6
        },
        
        // 案例页
        {
            path: '/Page',
            name: 'Page',
            component: Page
        },


        // 新闻页
        {
            path: '/n1',
            name: 'n1',
            component: n1
        },
    ]
})

Vue.use(Router)