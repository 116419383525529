<template>
    <div class="product-1">
        <div class="News-wrap">
            <h3>专业游艇码头 制造商</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">产品</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="title-wrap">
            <div class="title">
                <h3>ALUMINUM/STEEL PONTOON<br>游艇码头</h3>
            </div>
            <div class="img-wrap">
                <img src="../assets/Product/2.jpg" alt="">
            </div>
            <div class="content-wrap">
                <h2> ALUMINUM/STEEL PONTOON<br>
                    铝合金浮动码头/钢结构浮动码头<br><br></h2>

                <p>The main structure of the frame construction consists of anticorodal marine grade aluminium 6061-T6, welded together so as to obtain a solid supporting frame, completed by electrically isolated aluminium beams, on which the floors made of WPC (Wood Plastic Composite).Feature: Modern style, high performance, low cost, durable, anti-collision
                <br><br>

                    新型铝合金游艇码头采用航海级的6061铝合 金,T6热处理，MIG焊制而成，表面经过科学的处理,增强了框架
                    的抗氧化能力和抗腐蚀能力,具备良好的耐腐蚀性,适用于江河湖海，通用性强。<br><br>
                    1.Hot dip Galvanized Steel frame floating dock,stable and elegant.<br>
                    2.LLDPE floats or concrete floats with EPS foam, density at least 15kg/m³.<br>
                    3.WPC decking or other imported decking, fixed by stainless steel 316L screw and bolts.<br><br>
                    钢结构码头采用热镀浸锌防腐层槽钢,用在码头上必须经过防腐处理,码头性价比高,结构强度好,稳定性好
                    施工方便简单，</p>
            </div>
            <section class="gallery" id="gallery">
                <div class="box-container">

                    <div class="box" v-for="item in items" :key="item.id">
                        <img :src="item.img" alt="">
                        <!-- <span>2020.06 </span>
                    <h3>澳大利亚-Tom快艇码头</h3> -->
                    </div>

                </div>

            </section>

        </div>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [{
                        id: '01',
                        img: require('../assets/Case/Marinas/2/1.jpg')
                    },
                    {
                        id: '02',
                        img: require('../assets/Case/Marinas/2/2.jpg')
                    },
                    {
                        id: '03',
                        img: require('../assets/Case/Marinas/2/3.jpg')
                    },
                    {
                        id: '04',
                        img: require('../assets/Case/Marinas/2/4.jpg')
                    },
                    {
                        id: '05',
                        img: require('../assets/Case/Marinas/2/5.jpg')
                    },
                    {
                        id: '06',
                        img: require('../assets/Case/Marinas/2/6.jpg')
                    }

                ],
            }
        },
    }
</script>

<style src='../assets/css/Product.css'>

</style>